<template>
  <!-- 点赞组件 -->
  <div class="commentList">
    <ul>
      <li v-for="(item,index) in list" :key="'flist'+item.replyId">
        <img class="userImg" :src="item.headimgUrl">
        <p class="nickname">{{item.nickName}}</p>
        <div class="commentContainer" v-html="item.content"></div>
        <div class="commentInfo">
          <div class="time" v-asynDateDiff>{{item.createTime}}</div>
          <div class="commentBtn">
            <img class="comment" @click="goComment(item.commentId,item.replyId)" src="@/assets/img/home/comment.png">
            <like class="like" :liked="item.islike" @click.native="like(item.replyId,item.islike,index)" />
            {{item.praiseCount}}
          </div>
        </div>
        <ul v-if="item.replyList.length>0">
          <li v-for="(sitem,sindex) in item.replyList" :key="'slist'+sitem.replyId">
            <img class="userImg" :src="sitem.headimgUrl">
            <p class="nickname">{{sitem.nickName}}</p>
            <div class="commentContainer">
              <span v-if="sitem.anickName" class="actionque">@{{sitem.anickName}} </span>
              <div v-html="sitem.content"></div>
            </div>
            <div class="commentInfo">
              <div class="time" v-asynDateDiff>{{sitem.createTime}}</div>
              <div class="commentBtn">
                <img class="comment" @click="goComment(sitem.commentId,item.replyId,sitem.nickName,sitem.userId)"
                  src="@/assets/img/home/comment.png">
                <like class="like" :liked="sitem.islike"
                  @click.native="like(sitem.replyId,sitem.islike,sindex,index)" />
                {{sitem.praiseCount}}
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
  import like from './like'
  export default {
    name: "commentList",
    components: {
      like
    },
    props: {
      list: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data() {
      return {}
    },
    methods: {
      // 点赞
      like(commentId, islike, index, isTrue) {
        let status = !islike ? 0 : 1
        this.$api.home.like({
          id: commentId,
          type: 1,
          status: status
        }, res => {
          if (res.code === 200) {
            if (typeof isTrue !== 'number') {
              if (status === 0) {
                this.list[index].praiseCount++
                this.list[index].islike = true
              } else {
                this.list[index].praiseCount--
                this.list[index].islike = false
              }
            } else {
              console.log(isTrue)
              if (status === 0) {
                this.list[isTrue].replyList[index].praiseCount++
                this.list[isTrue].replyList[index].islike = true
              } else {
                this.list[isTrue].replyList[index].praiseCount--
                this.list[isTrue].replyList[index].islike = false
              }
            }

          }
        })
      },
      // 去评论
      goComment(commentId, replyId, nickName, userId) {
        if (userId) {
          this.$router.push({
            path: '/release',
            query: {
              commentId: commentId,
              parentId: replyId,
              nickName: nickName,
              userId: userId
            }
          })
        } else {
          this.$router.push('/release?parentId=' + replyId + '&commentId=' + commentId)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .commentList {
    width: 100%;
    height: auto;

    .actionque {
      color: royalblue;
    }

    ul {
      width: 100%;

      li {
        padding-left: 78px;
        position: relative;

        .userImg {
          width: 60px;
          height: 60px;
          box-sizing: border-box;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 0;
        }

        .nickname {
          font-size: 26px;
          color: #333333;
          line-height: 36px;
          margin-bottom: 10px;
        }

        .commentContainer {
          font-size: 28px;
          color: #666666;
          line-height: 40px;

          & /deep/ video {
            width: 100%;
            height: 360px;
            background-color: #000000;
          }

          img {
            width: 100%;
          }
        }

        .commentInfo {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .time {
            font-size: 24px;
            color: #999999;
            line-height: 34px;
          }

          .commentBtn {
            font-size: 24px;
            color: #999999;
            line-height: 34px;
            display: flex;

            .like {
              margin: 0 6px 0 40px;
            }

            .comment {
              width: 32px;
              height: 30px;
              word-break: break-all;
            }
          }
        }

      }
    }
  }
</style>
