<template>
  <!-- 点赞组件 -->
  <div class="like">
    <img v-show="liked" :src="likeImg" alt="">
    <img v-show="!liked" :src="unlikeImg" alt="">
  </div>
</template>

<script>
  export default {
    name: "like",
    props: {
      unlikeImg: {
        type: String,
        default: require('@/assets/img/home/zan-before.png'),
      },
      likeImg: {
        type: String,
        default: require('@/assets/img/home/zan-after.png'),
      },
      liked: Boolean
    },
    data() {
      return {}
    },
  }
</script>

<style scoped>
  .like {}

  .like img {
    width: 28px;
    height: 28px;
    vertical-align: inherit;
  }
</style>
