<template>
  <div class="activity-Details">
    <!--    <van-nav-bar :title="activity.name" left-arrow @click-left="back" />-->
    <div class="activity-header">
      <h3 class="title">{{activity.name}}</h3>
      <div class="user">
        <img :src="activity.favator" alt="">
        <div class="user-r">
          <p class="name">{{activity.createBy}}</p>
          <p class="readNum">{{activity.views}}人浏览 <span class="time" v-asynDateDiff>{{activity.createTime}}</span></p>
        </div>
      </div>
      <!-- 普通活动和表单获取展示 -->
<!--      <div v-if="activity.type === 2" class="content">-->
<!--        <vm-form-render ref="vmFormRef"  class="vmFormRefContent"></vm-form-render>-->
<!--      </div>-->
      <div class="content" v-html="activity.description"></div>
      <van-button v-if="isLoadingDetails" class="partakeBtn" @click="goDiscuss">参与讨论</van-button>
    </div>
    <!-- 讨论区域 -->
    <div class="discuss-content" id="commentListContent">
      <header>
        <p class="discussNum">回答 {{commentListLength}}</p>
        <van-dropdown-menu>
          <van-dropdown-item v-model="value2" :options="option2" @change="dropdownChange" />
        </van-dropdown-menu>
      </header>
      <van-list v-if="activity.type !== 2 || activity.formContent " v-model="loading" :finished="finished" @load="getCommentList">
        <van-empty v-if="commentList.length===0" description="暂无评论" />
        <div v-else class="discussCenterBox">
          <div v-for="(item,index) in commentList" :key="item.id" class="discussCenter">
            <div class="user">
              <img :src="item.headimgUrl" alt="">
              <p class="name">{{item.nickName}}</p>
              <p v-if="userId==''" class="look" @click="JustLook(item.userId)">仅看此人</p>
              <p v-else class="look" @click="JustLook('')">查看全部</p>
            </div>
            <!-- 加载主要内容 -->
            <vm-form-render ref="vmFormRefContent" class="vmFormRefContent" :form-json="activity.formContent" v-if="activity.type === 2"
              :form-data="item.content"></vm-form-render>
            <div v-else class="content" v-html="item.content"></div>
            <!-- 点赞区域 -->
            <div class="discuss-content-b">
              <div v-asynDateDiff>{{item.createTime}}</div>
              <div class="li">
                <div class="li_like"><img class="mar8" @click="goComment(item.commentId)"
                    src="@/assets/img/home/comment.png">
                  {{item.replyList.length}}
                </div>
                <div class="li_like">
                  <like class="mar8" :liked="item.islike" @click.native="like(item.commentId,index)" />
                  {{item.praiseCount}}
                </div>
              </div>
            </div>
            <div class="commentList" v-if="item.replyList.length>0">
              <h3 class="secondTitle">评论{{item.replyList.length}}</h3>
              <template v-if="item.replyList.length!==0">
                <commentList :list="item.replyList" />
              </template>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import like from '@/components/like'
  import commentList from '@/components/commentList'
  import router from "@/router/router";
  import until from '@/until/until.js'
  export default {
    name: "activityDetails",
    components: {
      like,
      commentList
    },
    data() {
      return {
        activity: {
          name: '',
          createBy: ''
        },
        isLoadingDetails:false,
        loading: false,
        finished: false,
        queryId: null,
        commentList: [],
        commentListLength: 0,
        value2: 0,
        pageNum: 1,
        pageSize: 15,
        option2: [{
          text: '时间倒序',
          value: 0
        }, {
          text: '评论数',
          value: 1
        }],
        userId: '',
      };
    },

    mounted() {
      this.queryId = this.$route.query.id
      this.getActivity(this.queryId)
    },
    methods: {
      goMarginTopById(value){
        let offsetTop = document.getElementById(value).offsetTop - 10;
        window.scrollTo(0,offsetTop);
      },
      goMarginTopByClass(value){
        // if (sessionStorage.getItem('scrollByOffsetTop')){
          let classElements= document.getElementsByClassName(value);
          if (classElements && classElements.length > 0){
            let offsetTop = classElements[0].offsetTop - 10;
            window.scrollTo(0,offsetTop);
          }
          // sessionStorage.removeItem('scrollByOffsetTop')
        // }
      },
      //仅看此人
      JustLook(userId) {
        this.pageNum = 1
        this.commentList = []
        this.userId = userId
        this.getCommentList()
      },
      back() {
        this.$router.back(-1)
      },
      //参与讨论
      goDiscuss() {
        if (this.activity.status == 0) {
          this.$toast('活动暂未开始，请稍后再来')
        } else if (this.activity.status == 2) {
          this.$toast('活动暂时关闭，请等待下次开启')
        } else {
          this.activity.type === 2 ? this.$router.push('./releseForm?id=' + this.queryId) : this.$router.push(
            '/release?id=' + this.queryId)
        }
      },
      //切换
      dropdownChange() {
        this.pageNum = 1
        this.commentList = []
        this.getCommentList(this.value2)
      },
      //获取活动详情
      getActivity(id) {
        this.$api.home.activity({
          id: id
        }, res => {
          if (res.code === 200) {
            // 活动结束，直接返回
            if (res.data.status == 2) {
              this.$toast('活动暂时关闭，请等待下次开启')
              router.push('/')
              return
            }

            //如果为表单类型活动
            if (res.data.type === 2) {
              res.data.formContent = JSON.parse(res.data.formContent)
              // this.$nextTick(() => {
              //   setTimeout(() => {
              //     this.$refs.vmFormRef.setFormJson(res.data.formContent)
              //
              //     setTimeout(() => {
              //       this.$refs.vmFormRef.disableForm()
              //       // this.$refs.vmFormRef.setReadMode()
              //     }, 10)
              //   })
              // })
            }
            this.activity = res.data
            this.isLoadingDetails = true
            document.title = this.activity.name
          }else{

          }
        })
      },
      //获取评论列表
      getCommentList(sort) {
        let commentData = {
          activityId: this.$route.query.id,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        }
        if (this.userId != '') {
          commentData.userId = this.userId
        }
        if (typeof this.value2 == 'number') {
          commentData.sort = this.value2
        }
        this.$api.home.commentList(commentData, res => {
          if (res.code === 200) {
            if (res.rows.length === 0) {
              this.finished = true
              return false
            }
            //表单活动特殊处理
            if (res.type === 2) {
              res.rows = res.rows.map(item => {
                item.content = until.isJSON(item.content) ?JSON.parse(item.content) :{}
                return item
              })
            }
            this.commentList = this.commentList.concat(res.rows)
            this.commentListLength = res.total

            if (this.pageNum === 1 && this.$route.query.fromMessage){
              this.$nextTick(() => {
                this.goMarginTopByClass("secondTitle")
              });
            }
            this.pageNum++
            this.loading = false
          }
        })
      },
      // 点赞
      like(commentId, index) {
        let status = !this.commentList[index].islike ? 0 : 1
        this.$api.home.like({
          id: commentId,
          type: 0,
          status: status
        }, res => {
          if (res.code === 200) {
            if (status === 0) {
              this.commentList[index].praiseCount++
              this.commentList[index].islike = true
            } else {
              this.commentList[index].praiseCount--
              this.commentList[index].islike = false
            }
          }
        })
      },
      // 去评论
      goComment(commentId) {
        this.$router.push('/release?commentId=' + commentId)
      }
    }
  };
</script>

<style lang="less" scoped>
  @import '~@/vform/vmFormRefContent.less';
  .activity-Details {
    width: 100%;
    background-color: #F1F1F1;
    word-wrap: break-word;
    /deep/ .van-cell--required::before{
      content: '';
    }
    .activity-header {
      width: 100%;
      background-color: #FFFFFF;
      box-sizing: border-box;
      padding: 40px 30px 30px;
      margin-bottom: 20px;

      .title {
        font-size: 36px;
        font-weight: 800;
        color: #333333;
        line-height: 50px;
        margin-bottom: 52px;
      }
    }

    .content {
      font-size: 32px;
      //font-weight: 500;
      color: #444444;
      line-height: 52px;

      /deep/ ul li {
        list-style: square;
        margin-left: 32px;
      }

      /deep/ ol li {
        list-style: decimal;
        margin-left: 32px;
      }

      & /deep/ img {
        width: 100%;
        margin-top: 24px;
        max-width: 100% !important;
        height: auto !important;
      }

      & /deep/ video {
        width: 100%;
        height: 360px;
        background-color: #000000;
      }

    }

    .partakeBtn {
      width: 690px;
      height: 80px;
      background: #3E639A;
      border-radius: 4px;
      font-size: 32px;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 30px;
    }
  }

  .user {
    display: flex;
    align-items: center;

    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }

    .name {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
    }

    .readNum {
      font-size: 24px;
      color: #999999;
      line-height: 34px;
    }

    .look {
      margin-left: auto;
      font-size: 28px;
      color: #999999;
      line-height: 40px;
    }
  }

  .discuss-content {
    width: 100%;
    background: #ffffff;


    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      box-sizing: border-box;
      height: 104px;
      font-size: 32px;
      font-weight: bold;
      color: #333333;
      line-height: 44px;
      border: 2px solid #EEEEEE;

      .van-dropdown-menu /deep/ .van-dropdown-menu__bar {
        box-shadow: none;

        .van-dropdown-menu__title {
          font-size: 28px;
          font-weight: 500;
          color: #999999;
          line-height: 40px;
        }
      }
    }

    .discussCenter {
      padding: 30px;
      box-sizing: border-box;
      border-bottom: 20px solid #F1F1F1;

      .discuss-content-b {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 28px;
        color: #999999;
        line-height: 40px;

        img {
          width: 26px;
          height: 26px;
        }

        .mar8 {
          margin-right: 8px;
        }

        .li {
          display: flex;
          align-items: center;

          .li_like {
            margin-left: 30px;
            display: flex;
            align-items: center;
          }
        }


      }
    }

    .commentList {
      margin: 34px 0 34px 0;
      word-break: break-all;
    }

    .secondTitle {
      font-size: 28px;
      color: #333333;
      line-height: 40px;
    }
  }
</style>
<style type="text/css">
  .discussCenter:last-child {
    border: 0 !important;
  }
</style>
